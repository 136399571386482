// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import isNull from 'lodash/isNull'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Form from 'antd/lib/form'
import 'antd/lib/form/style/css'

import Button from 'antd/lib/button'
import 'antd/lib/button/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Submit */
class Submit extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.formRef =
      isNull(this.props.forwardedRef) === false
        ? this.props.forwardedRef
        : this.props.refs.formRef

    this.setLoading = this.setLoading.bind(this)
  }

  /** setLoading */
  setLoading(loading) {
    this.setState({ loading })
  }

  /** render */
  render() {
    const {
      awaitingServerResponse = false,
      finishedCycle = false,
      disabled = false,
      serverMesage = undefined,
      conf: { formConf },
      style = {},
    } = this.props
    const { loading } = this.state
    const {
      allowReset = false,
      onReset = () => console.log('form reset'),
      canSubmit = true,
    } = formConf

    return (
      <Fragment>
        <Form.Item className={`field as-paragraph submit-button`} style={style}>
          <Button
            form="cart"
            type="primary"
            htmlType="submit"
            disabled={
              canSubmit === false ||
              disabled === true ||
              loading === true ||
              awaitingServerResponse === true ||
              finishedCycle === true
            }
            loading={awaitingServerResponse}
          >
            {finishedCycle === false
              ? awaitingServerResponse === false
                ? 'Next'
                : 'Processing...'
              : 'Submitted'}
          </Button>
        </Form.Item>
      </Fragment>
    )
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Submit
