// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import isNull from 'lodash/isNull'
import compose from 'recompose/compose'
import isUndefined from 'lodash/isUndefined'
import { connect } from 'react-redux'

import queryString from 'query-string'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Form from 'antd/lib/form'
import 'antd/lib/form/style/css'

import Steps from 'antd/lib/steps'
import 'antd/lib/steps/style/css'

import Button from 'antd/lib/button'
import 'antd/lib/button/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import getRandomArbitraryInt from '../../methods/getRandomArbitraryInt'

import Link from '../link'
import '../link/style.less'

import Name from '../form-fields/name'
import Email from '../form-fields/email'
import Phone from '../form-fields/phone-without-dialcodes'

import Comment from '../form-fields/comment'
import Submit from '../form-fields/submit'

import onSubmit from '../form-methods/on-submit'

import forwardRef from '../extensions/forward-ref'

import about from '../../seo/about.json'
import website from '../../seo/website.json'

import List from './list'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const { Step } = Steps

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ShoppingCart */
class ShoppingCart extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.formRef = this.props.refs.formRef

    this.state = {
      current: 0,
      max: 0,
      loading: false,
      resetLoading: false,
      formValues: {},
      confirmed: false,
      confirmedId: '',
      confirmedStatus: '',
    }

    this.onChange = this.onChange.bind(this)
    this.goNext = this.goNext.bind(this)
    this.goPrev = this.goPrev.bind(this)
    this.reset = this.reset.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.updateValues = this.updateValues.bind(this)
  }

  /** [componentDidMount description] */
  componentDidMount() {
    if (typeof window !== 'undefined') {
      const { conf, location: { search } = {} } = this.props
      const { formConf } = conf
      const { onReset = () => console.log('form reset') } = formConf

      if (search) {
        const parsed = queryString.parse(search)
        const { id = '', status = '' } = parsed
        this.formRef.current.resetFields()
        onReset()
        this.setState({
          confirmed: true,
          confirmedId: id,
          confirmedStatus: status,
          current: 2,
        })
      }
    }
  }

  /** [description] */
  onChange(current) {
    const nextCurrent = current
    const { max, confirmed } = this.state

    if (confirmed === false) {
      if (nextCurrent <= max) {
        this.setState({
          current,
        })

        if (typeof window !== 'undefined') {
          console.log('scroll onchange')
          setTimeout(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          }, getRandomArbitraryInt(10, 30))
        }
      }
    }
  }

  /** setLoading */
  setLoading(loading) {
    this.setState({ loading })
  }

  /** updateValues */
  updateValues(changedValues, allValues) {
    this.setState({ formValues: allValues })
  }

  /** reset */
  reset() {
    const { conf } = this.props
    const { formConf } = conf
    const { onReset = () => console.log('form reset') } = formConf

    this.setState({ resetLoading: true })
    setTimeout(() => {
      this.formRef.current.resetFields()
      onReset()
      this.setState({ current: 0, resetLoading: false })
    }, getRandomArbitraryInt(50, 70))
  }

  /** [description] */
  goNext() {
    const { current } = this.state
    const next = current + 1

    if (typeof window !== 'undefined') {
      console.log('scroll next')
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }, getRandomArbitraryInt(10, 30))
    }

    if (current === 0) {
      this.setState({ current: next, max: next })
    }

    if (current === 1) {
      this.formRef.current
        .validateFields(['name', 'email', 'phone', 'organisation'])
        .then((values) => {
          this.setState({
            current: next,
            max: next,
          })
        })
        .catch((errorInfo) => {
          // console.log('errorInfo', errorInfo)
        })
    }

    if (current === 2) {
      this.setState({
        current: next,
        max: next,
      })
    }
  }

  /** [description] */
  goPrev() {
    const { current } = this.state
    const prev = current - 1
    if (typeof window !== 'undefined') {
      console.log('scroll prev')
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }, getRandomArbitraryInt(10, 30))
    }

    this.setState({
      current: prev,
    })
  }

  /** standard renderer */
  render() {
    const {
      conf,
      onFinish,
      shoppingCartState,
      awaitingServerResponse,
      disabled,
      finishedCycle,
      serverMesage,
      requestId,
      objectsRequestedString,
    } = this.props
    const { shopConf, formConf } = conf
    const { totalCountOfObjects, totalPriceOfObjects } = shoppingCartState
    const {
      loading,
      resetLoading,
      current,
      formValues,
      confirmed,
      confirmedId,
      confirmedStatus,
    } = this.state
    const {
      allowReset = false,
      onReset = () => console.log('form reset'),
      canSubmit = true,
    } = formConf

    return (
      <div className="xform shopping-cart objects-list-with-extended-form as-paragraph">
        <Steps
          current={current}
          onChange={this.onChange}
          direction="horizontal"
          progressDot
          className="hidden-on-mobile hidden-on-tablet as-paragraph"
        >
          <Step title={<span className="as-paragraph">Review</span>} />
          <Step title={<span className="as-paragraph">Contribute</span>} />
          <Step title={<span className="as-paragraph">Confirmation</span>} />
        </Steps>
        <div>
          {confirmed === false && (
            <Fragment>
              <p style={{ display: current === 0 ? 'block' : 'none' }}>
                The paperback version of the book costs about ₹{about.bookPrice}
                , and if you'd like to purchase the paperback version of the
                book you can do so by following{' '}
                <Link to={website.bookUrl}>this link.</Link>
              </p>
              <p style={{ display: current === 0 ? 'block' : 'none' }}>
                Here you can choose to contribute for the web-book. We
                appreciate whatever you may give us. A contribution above and
                beyond the cost of the book will not only support the emergance
                of a new and equitable way to publish, but it will also enable
                us to keep books on Auroville, like this one, openly available
                for everyone.
              </p>
              <List
                conf={shopConf}
                finishedCycle={finishedCycle}
                style={{ display: current === 0 ? 'block' : 'none' }}
              />
              <Form
                id="cart"
                className="as-paragraph"
                scrollToFirstError
                ref={this.formRef}
                onFinish={onFinish}
                onValuesChange={this.updateValues}
              >
                <Name
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 1 ? 'block' : 'none' }}
                />
                <Email
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 1 ? 'block' : 'none' }}
                />
                <Phone
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 1 ? 'block' : 'none' }}
                />
              </Form>
              {isUndefined(serverMesage) === false && (
                <div
                  className="as-paragraph message"
                  style={{ display: current === 1 ? 'block' : 'none' }}
                >
                  <p style={{ marginBottom: '0.2rem' }}>
                    <strong>Registering your contribution</strong>
                  </p>
                  <p style={{ marginBottom: 0 }}>{serverMesage}</p>
                </div>
              )}
              <div
                className="as-paragraph button-group"
                style={{
                  display: current !== 2 ? 'flex' : 'none',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={this.goPrev}
                  style={{
                    display:
                      totalCountOfObjects > 0
                        ? current >= 1
                          ? finishedCycle === false
                            ? 'block'
                            : 'none'
                          : 'none'
                        : 'none',
                  }}
                  ghost
                  className="previous-button"
                  disabled={
                    canSubmit === false ||
                    disabled === true ||
                    awaitingServerResponse === true ||
                    finishedCycle === true
                  }
                >
                  Previous
                </Button>
                <Submit
                  {...this.props}
                  serverMesage={serverMesage}
                  finishedCycle={finishedCycle}
                  awaitingServerResponse={awaitingServerResponse}
                  conf={{ formConf }}
                  style={{
                    display:
                      totalCountOfObjects > 0
                        ? current === 1
                          ? finishedCycle === false
                            ? 'block'
                            : 'none'
                          : 'none'
                        : 'none',
                    marginBottom: 0,
                  }}
                />
                <Button
                  onClick={this.goNext}
                  style={{
                    display:
                      totalCountOfObjects > 0
                        ? current === 0
                          ? 'block'
                          : 'none'
                        : 'none',
                    marginBottom: 0,
                  }}
                  className="next-button"
                >
                  Next
                </Button>
              </div>
              <form
                action="https://talampay.auroville.org/api/transactions"
                method="post"
                style={{
                  display:
                    totalCountOfObjects > 0
                      ? current === 1
                        ? finishedCycle === true
                          ? 'block'
                          : 'none'
                        : 'none'
                      : 'none',
                }}
              >
                <input
                  type="hidden"
                  name="customer_name"
                  value={
                    isUndefined(formValues.name) === false
                      ? formValues.name
                      : ''
                  }
                />
                <input
                  type="hidden"
                  name="customer_email"
                  value={
                    isUndefined(formValues.email) === false
                      ? formValues.email
                      : ''
                  }
                />
                <input
                  type="hidden"
                  name="customer_phone"
                  value={
                    isUndefined(formValues.phone) === false
                      ? formValues.phone
                      : ''
                  }
                />
                <input
                  type="hidden"
                  name="source"
                  value="https://dream.books.prisma.haus/"
                />
                <input
                  type="hidden"
                  name="purpose"
                  value={objectsRequestedString}
                />
                <input type="hidden" name="source_order_id" value={requestId} />
                <input
                  type="hidden"
                  name="payment_account_id"
                  value="ddf6adff-e7a4-4885-a526-0d529613922b"
                />
                <input
                  type="hidden"
                  name="amount"
                  value={totalPriceOfObjects}
                />
                <input type="hidden" name="currency" value="INR" />
                <input
                  type="hidden"
                  name="return_url"
                  value="https://dream.books.prisma.haus/en/contribute"
                />
                <div className="as-paragraph" style={{ textAlign: 'right' }}>
                  <button className="ant-btn payment-button" type="submit">
                    Next
                  </button>
                </div>
              </form>
            </Fragment>
          )}
          {confirmed === true && (
            <div>
              <p>
                We have registered your contribution, and your payment is{' '}
                {confirmedStatus}.
              </p>
              <p>
                Your contribution reference number is:{' '}
                <strong style={{ textDecoration: 'underline' }}>
                  {confirmedId}
                </strong>
                . Please save this somewhere in-case there is an issue with the
                transfer.
              </p>
              <p>Thank you!</p>
            </div>
          )}
        </div>
      </div>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    shoppingCartState: state.shoppingCartState,
  }),
  (dispatch) => ({})
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------- Compose & Exports
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ShoppingCartWithGoogleForm = compose(
  forwardRef,
  onSubmit,
  withState
)(ShoppingCart)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ShoppingCartWithGoogleForm
