// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import isUndefined from 'lodash/isUndefined'
import isAlphanumeric from 'validator/lib/isAlphanumeric'
import isEmpty from 'validator/lib/isEmpty'
import trim from 'validator/lib/trim'
import blacklist from 'validator/lib/blacklist'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Form from 'antd/lib/form'
import 'antd/lib/form/style/css'

import Input from 'antd/lib/input'
import 'antd/lib/input/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import getTitle from '../form-methods/get-form-item-title'
import getPlaceholder from '../form-methods/get-form-item-placeholder'
// import getInfo from '../form-methods/get-form-item-info'
import getExtra from '../form-methods/get-form-item-extra'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Name */
const Name = ({
  fieldName = 'comment',
  conf: {
    formConf: {
      includeTitle = false,
      includePlaceholders = false,
      includeExtra = false,
      fields = { [fieldName]: {} },
      dateFormat = 'Do MMM, YYYY',
    },
  } = {},
  fieldConf = fields[fieldName],
  givenRules = fieldConf.rules,
  defaultRules = [
    {
      validator: (_, value) => {
        if (isUndefined(value) === true) {
          // not a problem, can be empty
          Promise.resolve()
        } else {
          const trimmed = trim(value)

          if (isEmpty(trimmed) === true) {
            // not a problem, can be empty
            Promise.resolve()
          } else {
            const blacklisted = blacklist(trimmed, [' -,'])

            if (isAlphanumeric(blacklisted) === false) {
              return Promise.reject(
                'Sorry, only letters (a-zA-Z), numbers (0-9) and few general punctuation characters (-,) accepted'
              )
            } else {
              return Promise.resolve()
            }
          }
        }
      },
    },
  ],
  awaitingServerResponse = false,
  finishedCycle = false,
  style = {},
}) => (
  <Fragment>
    {includeTitle === true && (
      <p className="field-title" style={style}>
        <span>{getTitle(fieldName, fieldConf)}</span>
      </p>
    )}
    <Form.Item
      name={fieldName}
      className={`field as-paragraph input-field--${fieldName}`}
      rules={typeof givenRules === 'undefined' ? defaultRules : givenRules}
      validateTrigger={['onChange', 'onBlur']}
      hasFeedback
      extra={
        includeExtra ? (
          <Fragment>{getExtra(fieldName, fieldConf)}</Fragment>
        ) : null
      }
      style={style}
    >
      <Input.TextArea
        autosize={{ minRows: 6, maxRows: 9 }}
        placeholder={
          includePlaceholders ? getPlaceholder(fieldName, fieldConf) : ' '
        }
        disabled={awaitingServerResponse === true || finishedCycle === true}
      />
    </Form.Item>
  </Fragment>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Name
