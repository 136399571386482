// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import isNull from 'lodash/isNull'
import keys from 'lodash/keys'
import forEach from 'lodash/forEach'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import getRandomArbitraryInt from '../../methods/getRandomArbitraryInt'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const onSubmit = (WrappedComponent) => {
  const returnObject = class extends React.Component {
    /** standard constructor */
    constructor(props) {
      super(props)

      this.state = {
        formTouched: false,
        awaitingServerResponse: false,
        serverMesage: undefined,
        finishedCycle: false,
      }

      this.formRef =
        isNull(this.props.forwardedRef) === false
          ? this.props.forwardedRef
          : this.props.refs.formRef

      this.onFinish = this.onFinish.bind(this)
    }

    /** [componentDidMount description] */
    componentDidMount() {
      const { formTouched } = this.state
      if (formTouched === false) {
        this.setState({ formTouched: true })
      }
    }

    /** [componentDidUpdate description] */
    componentDidUpdate() {
      // const { formTouched } = this.state
      // if (formTouched === true) {
      //   this.formRef.current.validateFields()
      // }
    }

    /** [onFinish description] */
    onFinish(/* values */) {
      this.setState({
        awaitingServerResponse: true,
        serverMesage: 'Setting up...',
      })
      this.formRef.current
        .validateFields()
        .then((values) => {
          setTimeout(() => {
            this.setState({
              serverMesage: 'Working...',
            })
            setTimeout(() => {
              this.setState({
                serverMesage: 'Sending data...',
              })
              setTimeout(() => {
                this.setState({
                  serverMesage: 'Waiting...',
                })
                setTimeout(() => {
                  setTimeout(() => {
                    const {
                      conf: {
                        formConf: {
                          fields = [],
                          alsoInclude = {},
                          macroId = 'AKfycbyIGwmPWpCw-HK6EDwdLrNOsXKILw5u5E8hedCmSr5hgSLmmhlm',
                          trace: {
                            success: successTrace = 'We have registered your request. Please click on Next.',
                          },
                          onFinish = () => console.log('Finished'),
                        },
                      } = {},
                    } = this.props
                    let queryString = ''
                    const fieldsKeys = keys(fields)
                    fieldsKeys.map((fieldKey) => {
                      const value = values[fieldKey]
                      queryString = `${queryString}${fieldKey}=${value}&`
                      return true
                    })
                    console.log(queryString)
                    console.log(values)

                    forEach(alsoInclude, (s, k) => {
                      queryString = `${queryString}${k}=${s}&`
                    })
                    fetch(
                      `https://script.google.com/macros/s/${macroId}/exec?${queryString}callback=?`,
                      {
                        method: 'GET',
                        mode: 'no-cors',
                      }
                    )
                      .then((response) => {
                        console.log('done')
                        console.log(response)
                        this.setState(
                          {
                            finishedCycle: true,
                            awaitingServerResponse: false,
                            serverMesage: successTrace,
                          },
                          () => {
                            onFinish(fields, values)
                          }
                        )
                      })
                      .catch((error) => {
                        console.log('error')
                      })
                  }, getRandomArbitraryInt(50, 100))
                }, getRandomArbitraryInt(50, 100))
              }, getRandomArbitraryInt(50, 100))
            }, getRandomArbitraryInt(50, 100))
          }, getRandomArbitraryInt(50, 100))
        })
        .catch((errorInfo) => {
          console.log('form fields error')
          console.log(errorInfo)
        })
    }

    /** render */
    render() {
      const { awaitingServerResponse, serverMesage, finishedCycle } = this.state

      return (
        <WrappedComponent
          {...this.props}
          onFinish={this.onFinish}
          awaitingServerResponse={awaitingServerResponse}
          serverMesage={serverMesage}
          finishedCycle={finishedCycle}
        />
      )
    }
  }

  return returnObject
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default onSubmit
